import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/site/src/components/layout.tsx";
import { graphql, Link } from "gatsby";
import Title from "@lekoarts/gatsby-theme-minimal-blog/src/components/title";
import PortfolioListItem from "../../../src/components/portfolio-list-item";
import { Grid } from 'theme-ui';
import { Box } from "@theme-ui/components";
export const pageQuery = graphql`
  query {
    allStocksJson {
      nodes {
        fields {
          slug
        }
        holdings {
          data
          key
        }
        name
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Title text="Stocks" mdxType="Title" />
    <p>{`Each stock listed below links to a page of detailed analysis showing the returns and risk of the stock.`}</p>
    <div>
  <Grid gap={50} columns={"repeat(auto-fill, 350px)"} mdxType="Grid">
    {props.data.allStocksJson.nodes.map(stock => <PortfolioListItem key={stock.fields.slug} portfolio={stock} mdxType="PortfolioListItem" />)}
  </Grid>
    </div>
    <p>{`If you would like to see an additional stock added, please `}<a parentName="p" {...{
        "href": "https://github.com/michaeldbianchi/darby/issues"
      }}>{`create an issue`}</a>{` in the main `}<a parentName="p" {...{
        "href": "https://github.com/michaeldbianchi/darby"
      }}>{`project page`}</a>{` or submit a PR.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      